import React, { Component, useEffect, useState } from 'react'
import User from '../services/User'
import { ApiService } from '../services/ApiService'
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Doughnut, Pie } from 'react-chartjs-2'

import LoadingContext from '../ToggleContext'
const mSwal = withReactContent(Swal)

function withParams(Component) {
	return (props) => <Component {...props} params={useParams()} />
}
window.copyToClipboard = (text) => {
	const input = document.createElement('textarea')
	input.value = text
	document.body.appendChild(input)
	input.select()
	document.execCommand('copy')
	document.body.removeChild(input)
	Swal.close()
}

export class CampaisnResults extends Component {
	static contextType = LoadingContext

	constructor(props) {
		super(props)
		this.state = {}
		this.reset()
	}
	transactionHistoryOptions = {
		responsive: true,
		maintainAspectRatio: true,
		segmentShowStroke: false,
		// cutoutPercentage: 70,
		elements: {
			arc: {
				borderWidth: 0,
			},
		},
		legend: {
			display: true,
		},
		tooltips: {
			enabled: true,
		},
	}

	reset() {
		const today = new Date()
		const nextThreeDays = new Date(today.setDate(today.getDate() + 3))
		this.state = {
			cmpName: '',
			cmpSubject: '',
			cmpSubjectB: '',
			cmpGroup: '',
			cmpRecipients: 0,
			cmpStats: { o: 0 },
			cmpDate: nextThreeDays,
			emojisOpen: false,
			emojisOpenB: false,
			cmpType: 'basic',
			opensData: {
				labels: ['Opened', 'Skipped'],
				datasets: [
					{
						data: [0, 0],
						backgroundColor: ['#553344', '#883344'],
					},
				],
			},
			transactionHistoryData: {
				labels: ['Mobile', 'Desktop', 'Tablet'],
				datasets: [
					{
						data: [0, 0, 0],
						backgroundColor: ['#445566', '#883344', '#ffab00'],
					},
				],
			},
		}
	}

	async componentDidMount() {
		this.context.setLoading_(true)
		const cmpId = this.props.params.cmpId
		this.setState({ cmpId: cmpId })
		let user = await User.get()
		if (cmpId) {
			let tempdata = await ApiService.get(`fairymailer/getCampaigns/?filters[uuid]=${this.props.params.cmpId}&populate=recp_groups`, user.jwt)
			if (tempdata && tempdata.data && tempdata.data.data) {
				console.log('campaign', tempdata.data.data[0])
				this.setState({ campaign: tempdata.data.data[0] })
				this.setState({
					cmpName: this.state.campaign?.name,
					cmpSubject: this.state.campaign?.subject,
					cmpSubjectB: this.state.campaign?.subject_b,
					cmpStats: this.state.campaign?.stats,
					cmpRecipients: this.state.campaign?.recipients,
					cmpDate: this.state.campaign?.date,
					cmpType: this.state.campaign?.type,
					cmpGroup: this.state.campaign?.recp_groups
						? this.state.campaign?.recp_groups.map((gr) => {
								return { value: gr.id, label: gr.name }
						  })
						: [],
				})
				this.context.setLoading_(false)
				console.log('state', this.state)
				let opensData = this.state.opensData
				let transactionHistoryData = this.state.transactionHistoryData
				opensData.datasets[0].data = [this.state.cmpStats?.or, 100 - this.state.cmpStats?.or]
				transactionHistoryData.datasets[0].data = [
					this.state.cmpStats?.mob ? this.state.cmpStats?.mob : 0,
					this.state.cmpStats?.des ? this.state.cmpStats?.des : 0,
					this.state.cmpStats?.tab ? this.state.cmpStats?.tab : 0,
				]
				this.setState({ opensData: opensData, transactionHistoryData: transactionHistoryData })
				return
			} else {
				mSwal.fire({ icon: 'error', text: 'Failed to load campaign data.' })
				this.context.setLoading_(false)
				return
			}
		}
		this.context.setLoading_(false)
	}

	render() {
		const { campaigns, meta, error, emojisOpen, emojisOpenB } = this.state
		const today = new Date()
		const nextThreeDays = new Date(today.setDate(today.getDate() + 3))

		return (
			<div className="campaign-editor-container">
				<div className="row">
					<div className="col-sm-12 grid-margin stretch-card">
						<div className="card">
							<div className="card-body">
								<div className="card-title">
									{this.state.cmpId ? (
										this.state.campaign ? (
											<div>
												<h2>
													<small>Results of </small> {this.state.campaign.name} {console.log('adas')}
													<br></br>
													<small style={{ fontSize: '14px' }}>Subject: {this.state.campaign.subject}</small>
													{this.state.campaign?.subject_b && this.state.campaign?.subject_b?.length > 0 ? (
														<div>
															<br></br>
															<small style={{ fontSize: '14px' }}>Subject B: {this.state.campaign.subject_b}</small>
														</div>
													) : (
														''
													)}
												</h2>
												<a className="btn btn-sm btn-primary" target="_blank" href={'/api/fairymailer/load-campaign-body/' + this.state.campaign.uuid}>
													Preview Email in Browser
												</a>
											</div>
										) : (
											<Skeleton />
										)
									) : (
										<h2 className="">Loading...</h2>
									)}
								</div>
								<div className="row cards">
									<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
										<div className="card" style={{ background: '#000' }}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">
														<div className="d-flex align-items-center align-self-start">
															<h3 className="mb-0">{this.state.campaign?.recipients ? this.state.campaign.recipients : <Skeleton />}</h3>
														</div>
													</div>
													<div className="col-3">
														<div className="icon icon-box-success ">
															<span className="mdi mdi-account-box icon-item"></span>
														</div>
													</div>
												</div>
												<h6 className="text-muted font-weight-normal">Recipients</h6>
											</div>
										</div>
									</div>

									<div className="col-md-3 col-sm-6 grid-margin stretch-card">
										<div className="card" style={{ background: '#000' }}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">
														<div className="d-flex align-items-center align-self-start">
															<h3 className="mb-0">0.00%</h3>
														</div>
													</div>
													<div className="col-3">
														<div className="icon icon-box-success ">
															<span className="mdi mdi-account-multiple-minus icon-item"></span>
														</div>
													</div>
												</div>
												<h6 className="text-muted font-weight-normal">Unsubscribed</h6>
											</div>
										</div>
									</div>

									<div className="col-md-3 col-sm-6 grid-margin stretch-card">
										<div className="card" style={{ background: '#000' }}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">
														<div className="d-flex align-items-center align-self-start">
															<h3 className="mb-0">0.00%</h3>
														</div>
													</div>
													<div className="col-3">
														<div className="icon icon-box-success ">
															<span className="mdi mdi-account-off"></span>
														</div>
													</div>
												</div>
												<h6 className="text-muted font-weight-normal">Spam complaints</h6>
											</div>
										</div>
									</div>

									<div className="col-md-3 col-sm-6 grid-margin stretch-card">
										<div className="card" style={{ background: '#000' }}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">
														<div className="d-flex align-items-center align-self-start">
															<h3 className="mb-0">0.00%</h3>
														</div>
													</div>
													<div className="col-3">
														<div className="icon icon-box-success ">
															<span className="mdi mdi-alert-circle"></span>
														</div>
													</div>
												</div>
												<h6 className="text-muted font-weight-normal">Bounce</h6>
											</div>
										</div>
									</div>

									{/* <div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
                    <div className="card" style={{background:'#000'}}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-9">
                            <div className="d-flex align-items-center align-self-start">
                              <h3 className="mb-0">{ this.state.campaign?.attributes?.stats ? this.state.campaign.stats.cr + '%' : <Skeleton/> }</h3>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="icon icon-box-success ">
                            <span className="mdi mdi-percent icon-item"></span>
                            </div>
                          </div>
                        </div>
                        <h6 className="text-muted font-weight-normal">Clicks Rate</h6>
                      </div>
                    </div>
                  </div> */}
								</div>
								<div>{this.state.cmpType === 'absplit' && <h4 className="">Subject : {this.state.cmpSubject}</h4>}</div>
								<div className="row cars-subs">
									<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
										<div className="card" style={{ background: '#000' }}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">
														<div className="d-flex align-items-center align-self-start">
															<h3 className="mb-0">{this.state.campaign?.stats ? this.state.campaign.stats.o : <Skeleton />}</h3>
														</div>
													</div>
													<div className="col-3">
														<div className="icon icon-box-success ">
															<span className="mdi mdi-bookmark-check icon-item"></span>
														</div>
													</div>
												</div>
												<h6 className="text-muted font-weight-normal">Opens</h6>
											</div>
										</div>
									</div>

									<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
										<div className="card" style={{ background: '#000' }}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">
														<div className="d-flex align-items-center align-self-start">
															<h3 className="mb-0">{this.state.campaign?.stats ? this.state.campaign.stats.or + '%' : <Skeleton />}</h3>
														</div>
													</div>
													<div className="col-3">
														<div className="icon icon-box-success ">
															<span className="mdi mdi-percent icon-item"></span>
														</div>
													</div>
												</div>
												<h6 className="text-muted font-weight-normal">Open Rate</h6>
											</div>
										</div>
									</div>

									<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
										<div className="card" style={{ background: '#000' }}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">
														<div className="d-flex align-items-center align-self-start">
															<h3 className="mb-0">{this.state.campaign?.stats ? this.state.campaign.stats.c : <Skeleton />}</h3>
														</div>
													</div>
													<div className="col-3">
														<div className="icon icon-box-success ">
															<span className="mdi mdi-mouse icon-item"></span>
														</div>
													</div>
												</div>
												<h6 className="text-muted font-weight-normal">Clicks</h6>
											</div>
										</div>
									</div>
									<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
										<div className="card" style={{ background: '#000' }}>
											<div className="card-body">
												<div className="row">
													<div className="col-9">
														<div className="d-flex align-items-center align-self-start">
															<h3 className="mb-0">{this.state.campaign?.stats ? this.state.campaign.stats.cr + '%' : <Skeleton />}</h3>
														</div>
													</div>
													<div className="col-3">
														<div className="icon icon-box-success ">
															<span className="mdi mdi-percent icon-item"></span>
														</div>
													</div>
												</div>
												<h6 className="text-muted font-weight-normal">Click Rate</h6>
											</div>
										</div>
									</div>
								</div>

								{this.state.cmpType === 'absplit' && (
									<>
										<div>
											<h4 className="">Subject : {this.state.cmpSubjectB}</h4>
										</div>
										<div className="row cars-subs">
											<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
												<div className="card" style={{ background: '#000' }}>
													<div className="card-body">
														<div className="row">
															<div className="col-9">
																<div className="d-flex align-items-center align-self-start">
																	<h3 className="mb-0">{this.state.campaign?.stats ? this.state.campaign.stats.ob : <Skeleton />}</h3>
																</div>
															</div>
															<div className="col-3">
																<div className="icon icon-box-success ">
																	<span className="mdi mdi-bookmark-check icon-item"></span>
																</div>
															</div>
														</div>
														<h6 className="text-muted font-weight-normal">Opens</h6>
													</div>
												</div>
											</div>

											<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
												<div className="card" style={{ background: '#000' }}>
													<div className="card-body">
														<div className="row">
															<div className="col-9">
																<div className="d-flex align-items-center align-self-start">
																	<h3 className="mb-0">{this.state.campaign?.stats ? this.state.campaign.stats.obr + '%' : <Skeleton />}</h3>
																</div>
															</div>
															<div className="col-3">
																<div className="icon icon-box-success ">
																	<span className="mdi mdi-percent icon-item"></span>
																</div>
															</div>
														</div>
														<h6 className="text-muted font-weight-normal">Open Rate</h6>
													</div>
												</div>
											</div>

											<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
												<div className="card" style={{ background: '#000' }}>
													<div className="card-body">
														<div className="row">
															<div className="col-9">
																<div className="d-flex align-items-center align-self-start">
																	<h3 className="mb-0">{this.state.campaign?.stats ? this.state.campaign.stats.cb : <Skeleton />}</h3>
																</div>
															</div>
															<div className="col-3">
																<div className="icon icon-box-success ">
																	<span className="mdi mdi-mouse icon-item"></span>
																</div>
															</div>
														</div>
														<h6 className="text-muted font-weight-normal">Clicks</h6>
													</div>
												</div>
											</div>
											<div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
												<div className="card" style={{ background: '#000' }}>
													<div className="card-body">
														<div className="row">
															<div className="col-9">
																<div className="d-flex align-items-center align-self-start">
																	<h3 className="mb-0">{this.state.campaign?.stats ? this.state.campaign.stats.cbr + '%' : <Skeleton />}</h3>
																</div>
															</div>
															<div className="col-3">
																<div className="icon icon-box-success ">
																	<span className="mdi mdi-percent icon-item"></span>
																</div>
															</div>
														</div>
														<h6 className="text-muted font-weight-normal">Click Rate</h6>
													</div>
												</div>
											</div>
										</div>
									</>
								)}

								<div className="row device-chart">
									<div className="col-md-4">
										<h3>Device opened</h3>
										<Pie data={this.state.transactionHistoryData} options={this.transactionHistoryOptions} />
									</div>
									<div className="col-md-4">
										<h3>Opens</h3>
										<Doughnut data={this.state.opensData} options={this.transactionHistoryOptions} />
									</div>
								</div>

								<div className="row row-links">
									<div className="col-lg-12 grid-margin stretch-card">
										<div className="card">
											<div className="card-body">
												<h4 className="card-title">Link Activity</h4>
												<div className="table-responsive">
													<table className="table table-hover">
														<thead>
															<tr>
																<th>Link</th>
																<th>Unique Clicks</th>
																<th>Total Clicks</th>
															</tr>
														</thead>
														<tbody>
															{this.state.cmpStats?.l &&
																Object.keys(this.state.cmpStats?.l).map((link) => {
																	return (
																		<tr>
																			<td>{link}</td>
																			<td>{this.state.cmpStats.l[link]}</td>
																			<td className="text-success">
																				{' '}
																				{this.state.cmpStats.l[link]}
																				<i className="mdi mdi-arrow-up"></i>
																			</td>
																		</tr>
																	)
																})}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withParams(CampaisnResults)
